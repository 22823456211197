import CourseVideoIcon from '../Icons/CourseVideo'
import CoursePDFIcon   from '../Icons/CoursePDF'
import CourseFullIcon  from '../Icons/CourseFull'

const pricings_configs =
{
	pdf:
	{
		slug: 'theorique',
		Icon: CoursePDFIcon
	},

	pdf_cnov:
	{
		slug: 'pratique-avec-cnov',
		Icon: CoursePDFIcon
	},

	full:
	{
		slug: 'theorique-et-pratique',
		Icon: CourseFullIcon
	},

	videos:
	{
		slug: 'pratique',
		Icon: CourseVideoIcon
	}
};

Object.keys(pricings_configs).forEach(code => pricings_configs[code].code = code);

export default pricings_configs;