import { Link } from 'react-router-dom'

import { RiInstagramFill } from 'react-icons/ri'
import { FaLinkedinIn }    from 'react-icons/fa'
import { FaFacebookF }     from 'react-icons/fa'
import { FaTiktok }        from 'react-icons/fa'

import Logo from '../Pure/Logo'

export default function FooterTop ()
{
	return (
		<div className="o-container c-footer__top-content">

			<Link to="/" className="c-footer__logo-container">
				<Logo type="white" className="c-footer__logo" />
			</Link>

			<ul className="c-footer__links">
				<li className="c-footer__link-item">
					<Link to="/" className="c-footer__links-a">
						Accueil
					</Link>
				</li>

				<li className="c-footer__link-item">
					<Link to="/qui-sommes-nous" className="c-footer__links-a">
						Qui sommes-nous
					</Link>
				</li>

				<li className="c-footer__link-item">
					<Link to="/formations-pratiques"
						className="c-footer__links-a">
						Formation pratique
					</Link>
				</li>

				<li className="c-footer__link-item">
					<Link to="/formations-theoriques"
						className="c-footer__links-a">
						Formation théorique
					</Link>
				</li>

				<li className="c-footer__link-item">
					<Link to="/contact" className="c-footer__links-a">
						Contact
					</Link>
				</li>
			</ul>

			<ul className="c-footer__social">

				<li className="c-footer__social-item">
					<a href="https://www.facebook.com/OAAFormations"
						className="c-footer__social-a"
						target="_blank" rel="noreferrer">
						<FaFacebookF className="c-footer__social-icon" />
						<span className="u-readers-only">
							Facebook
						</span>
					</a>
				</li>

				<li className="c-footer__social-item">
					<a href="https://www.instagram.com/osteopathy_animal_academy/"
						className="c-footer__social-a"
						target="_blank" rel="noreferrer">
						<RiInstagramFill className="c-footer__social-icon" />
						<span className="u-readers-only">
							Instagram
						</span>
					</a>
				</li>

				<li className="c-footer__social-item">
					<a href="https://www.linkedin.com/company/oaaformations"
						className="c-footer__social-a"
						target="_blank" rel="noreferrer">
						<FaLinkedinIn className="c-footer__social-icon" />
						<span className="u-readers-only">
							LinkedIn
						</span>
					</a>
				</li>

				<li className="c-footer__social-item">
					<a href="https://www.tiktok.com/@oaaformations"
						className="c-footer__social-a"
						target="_blank" rel="noreferrer">
						<FaTiktok className="c-footer__social-icon" />
						<span className="u-readers-only">
							TikTok
						</span>
					</a>
				</li>

			</ul>
		</div>
	);
}