import { Link } from 'react-router-dom'

export default function FooterLegal ()
{
	return (
		<div className="o-container c-footer__legal-content">

			<p className="c-footer__copyright">
				Copyright © 2024 OAA Online.
			</p>

			<ul className="c-footer__legal-links">
				<li className="c-footer__legal-item">
					<Link className="c-footer__legal-a" to="/mentions-legales">
						Mentions légales
					</Link>
				</li>

				<li className="c-footer__legal-item">
					<a className="c-footer__legal-a"
						href="/documents/CGV-CGU_OAA_Online.pdf"
						target="_blank" rel="noreferrer">
						CGU &amp; CGV
					</a>
				</li>
			</ul>

		</div>
	);
}