export const site_name = 'OAA Online';

export const logo = '/images/logo/logo-oaa.svg';

export const white_logo = '/images/logo/logo-oaa-blanc.svg';

export const colors =
{
	primary: '#FD780F'
};

export const js_version = '1.1.2';

export const js_refresh_rate = { minutes: 30 }; // luxon Duration params

export const static_CDN = 'https://cdn-oaa-online.s3.gra.io.cloud.ovh.net';

export const videos_count = 1017;
export const PDFs_count = 320;

export const allowed_payments_methods =
[
	{
		value: 'check',
		label: 'Chèque',
		by_label: 'par chèque'
	},
	{
		value: 'transfer',
		label: 'Virement',
		by_label: 'par virement'
	}
];

export const videos_guard_text =
(
	'Réservé aux détenteurs d\'une des offres Théorique.'
);

export const TIMEZONE = { zone: 'Europe/Paris' }; // luxon timezone

const config = { site_name, logo, colors, js_version, js_refresh_rate, white_logo, static_CDN, videos_count, PDFs_count, allowed_payments_methods };

export default config;